@import url('https://use.typekit.net/mjw0jrk.css');
@import url('https://fonts.googleapis.com/css?family=Lato');


.App {
  text-align: center;
}

.window {
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100%;
}


.flex {
  display: flex;
}


.tag-box {
  margin-top: 50px;
  border: none;
  border-bottom: 2px solid black;
  width: 100%;
}

.description-box {
  border: none;
  border-bottom: 2px solid black;
  width: 100%;
}


.image-column {
  display: inline-block;
  width: 18%;
  margin-left: 1.666%;
  vertical-align: top;
  position: relative;
}

.content-image {
  margin-top: 10px;
  width: 100%;
  height: auto;
  border-radius: 17.5px;
}




.Modal {
  position: absolute;
  width: 50%;
  margin-left: 25%;
  background-color: papayawhip;
  top: 50%;
  transform: translateY(-50%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.height{

  height: 100vh;
}

.form{

  position: relative;
}


.form span{

  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;

}

.left-pan{
  padding-left: 7px;
}

.left-pan i{

  padding-left: 10px;
}

.form-input{

  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus{

  box-shadow: none;
  border:none;
}

.aesthetic-button {
  border-radius: 25px;
  background-color: #c2ba50;
  color: white;
  border: none;
  padding: 5px 10px 5px 10px;
  margin-right: 5px;
  text-decoration: none;
  white-space: nowrap;
  margin-top: 10px;
}

.label-button {
  border: none;
  margin-right: 10px;
  background-color: #efefef;
  padding: 0px 8px 0px 8px;
  white-space: nowrap;
}
.gold-text {
  color: #c2ba50;
}

.blue-text {
  color: #396de0;
}

.gold-btn {
  background-color: #c2ba50;
  color: white;
  border: 2px solid #c2ba50;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}
.pointer:hover {
  cursor: pointer;
}

.gold-inverse-btn {
  background-color: white;
  border: 2px solid #c2ba50;
  color: #c2ba50;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-btn {
  background-color: #396de0;
  border: 2px solid #396de0;
  color: white;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-inverse-btn {
  background-color: white;
  border: 2px solid #396de0;;
  color: #396de0;;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-inverse-btn:hover {
  background-color: #396de0;
  border: 2px solid #396de0;
  color: white;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.light-grey-btn {
  background-color: #EFEFEF;
  color: #737373;
  border: none;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  font-weight : bold;
}

:focus {
  outline: none !important;
}

body, html {
  font-family: 'lato', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.standard-font-light {
  font-family: 'lato', sans-serif;
  font-weight: 500;
  font-style: normal;
  color: white;
  padding: 10px;
  text-align: center;
}

.font-medium {
  font-family: 'forma-djr-display', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.font-bold {
  font-family: 'forma-djr-display', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.forma-font {
  font-family: 'forma-djr-display', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.x-p {
  display: inline-block;
  float: right;
  color: #737373;
}

.grey-input {
  background-color: #EFEFEF;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.grey-input textarea {
  color: #737373;
}

.grey-input::placeholder {
  color: #737373;
}

.grey-textarea {
  background-color: #EFEFEF;
  color: #737373;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
  resize: none;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

textarea {
  resize: none;
}

.blue-color {
  color: #396de0
}

.relative-div {
  position: relative;
}

.input-limit-span {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #737373;
}

.textarea-limit-span {
  position: absolute;
  right: 10px;
  bottom: 15px;
  color: #737373;
}

.centered-text-div {
  text-align: center;
  margin-top: 50px;
}

.blue-text {
  color: #396de0;
}

.red-btn {
   background-color: white;
   border: 1px solid red;
   color: red;
   border-radius: 25px;
   padding: 5px 10px 5px 10px;
   font-weight: bold;
 }

.red-btn:hover {
  background-color: red;
  border: 1px solid red;
  color: white;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
}

::-webkit-scrollbar {
  display: none;
}

.justified-image {
  background-position: center center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.datingImg {
  width: 100%;
  height: auto;
}

.datingButton {
  border-radius: 25px;
  position: absolute;
  bottom: 180px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFFF;
  border: none;
  color: #236fea;
  padding: 10px 20px 10px 20px;
  white-space:nowrap;
}

.datingDiv {
  position:relative;
  display: block;
  min-height: 100vh;
  background-color: #236fea;
}

.big-upload-preview-img {
  width: auto;
  height: auto;
  max-height: 400px;
  margin-left: 5px;
  border-radius: 10px;
}

.upload-preview-img {
  width: auto;
  height: auto;
  max-height: 200px;
  margin-left: 5px;
  border-radius: 10px;
}