.upload-column {
    width: 50%;
    display: inline-block;
    padding-bottom: 20px;
    flex: 1;
}

.single-column {
    width: 50%;
    margin-left: 25%;
    display: inline-block;
    padding-bottom: 20px;
}

.tag-column {
    display: inline-block;
    width: 50%;
    position: relative;
    margin-top: 2.5vh;
    flex: 1;
    margin-bottom: 20px;
    margin-right: 15px;
}

.input-box {
    height: 65vh;
    position: relative;
    margin-top: 2.5vh;
}

.upload-image-preview {
    width: 50%;
    display: inline-block;
}

.upload-image-preview img {
    width: 90%;
    height: auto;
    margin-left: 5%;
    border-radius: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.upload-image-preview video {
    width: 90%;
    height: auto;
    margin-left: 5%;
    border-radius: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.upload-display {
    width: 50%;
    display: inline-block;
    position: relative;
    padding-right: 20px;
    margin-top: 20px;
}

.upload-modal {
    width: 800px;
    background-color: white;
    border-radius: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.upload-background {
    background-color: #fcf5e8;
    padding-top: 7.5vh;
    flex-grow : 1;
}

.aesthetic-input {
    border: none;
    border-bottom: 3px solid #c2ba50;
    width: 75%;
}

.aesthetic-input:disabled {
    background: transparent;
}

.aesthetic-input::placeholder{
    color: #A19B9A;
    font-weight: bold;
    font-family: 'forma-djr-display', sans-serif;
    font-size: 18px;
}

.label-input {
    border: none;
    border-bottom: 3px solid #c2ba50;
    width: 50%;
}

.label-input::placeholder{
    color: #A19B9A;
    font-weight: bold;
    font-family: 'forma-djr-display', sans-serif;
    font-size: 18px;
}

.label-input:disabled {
    background: transparent;
}

.aesthetic-dropdown {
    position: absolute;
    background-color: #fcf5e8;
    max-height: 20vh;
    width: 75%;
    overflow: scroll;
    z-index: 1;
}

.aesthetic-dropdown-p {
    margin-left: 5px;
}

.aesthetic-container {
    position: absolute;
    top: 10vh;
    width: 100%;
}

.label-container {
    position: absolute;
    top: 25vh;
    width: 100%;
}

.upload-aesthetics-div {
    margin-top: 15px;
}

.upload-submission-container {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    min-height: 450px;
}

.upload-submission-footer-div {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.upload-submission-footer-div button:last-child {
    margin-left: 10px;
}

.selected-image {
    text-align: center;
}

.selected-image img {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-top: 2.5vh;
    border-radius: 25px;
    object-fit: cover;
    margin-bottom: 10px;
    max-height: 65vh;
}

.selected-image video {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-top: 2.5vh;
    border-radius: 25px;
    object-fit: cover;
    margin-bottom: 10px;
    max-height: 65vh;
}

.upload-box {
    background-color: #EFEFEF;
    border-radius: 25px;
    height: 65vh;
    margin-left: 5%;
    width: 90%;
    margin-top: 2.5vh;
    text-align: center;
    position: relative;
}

.upload-box h5 {
    margin-top: 10px;
}

.upload-lightbox {
    background-color: #FFFFFF;
    border-radius: 25px;
    min-height: 70vh;
    width: 70%;
    margin-left: 15%;
    margin-bottom: 25px;
}

.upload-container {
    display: flex;
    position: relative;
    max-height: 90vh;
}

.upload-submit-div button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.upload-img {
    margin-top: 12.5vh;
    width: 30%;
    height: auto;
}

.upload-box:hover {
    cursor: pointer;
}

.upload-text {
    position: absolute;
    bottom: 40px;
    left: 10%;
    width: 80%;
}

.upload-text p {
    margin-bottom: 2px;
}

.submitted-h {
    font-size: 60px;
    line-height: 60px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.dude-h {
    text-decoration: underline;
    font-size: 36px;
}

.remove-upload-btn {
    border-radius: 25px;
    background-color: red;
    color: white;
    font-weight: 700;
}

.remove-upload-btn:hover {
    border-radius: 25px;
    background-color: red;
    color: white;
    font-weight: 700;
}

.label-button {
    margin-bottom: 10px;
}

.loading-div {
    background-color: rgba(24, 47, 96, .7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 25px;
}

.loading-div img {
    width: 50px;
    height: auto;
    top: 45%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.aesthetic-warning-p {
    font-size: 12px;
    color: #737373;
    margin-top: -10px;
}

.description-input {
    border: none;
    border-bottom: 3px solid #c2ba50;
    width: 75%;
    height: 30px;
    max-height: 200px;
}

.read-license-div {
    text-align: center;
    padding-top: 5px;
}

.read-license-div p {
    display: inline-block;
    margin-bottom: 0;
}

.error-message {
    color: red;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
}